import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Dashboard() {
    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)
    const [dataready, setdataready] = useState(false)

    /// use profits to display earnings too and also
    const [profits, setprofits] = useState(0)

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    function cleanDate(d) {
        var date = new Date(d);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        console.log("Date: " + date.getDate() +
            "/" + (months[date.getMonth() + 1]) +
            "/" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds());
        return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setOpen(!open);
            planduecheck(userdetails)
            console.log(userdetails.totalearnings)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                    setloading(false)

                } else {
                    setloggedin(false);
                    setOpen(!open);
                    navigate("/");
                }
            });
        }
    }, []);


    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    console.log(doc.data())
                    setloggedin(true)
                    setOpen(!open);
                    planduecheck(doc.data())
                    setdataready(true)
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };


    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const logout = async () => {
        const let1 = await setloggedin(false);
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };

    function addHoursToDate(date, hours) {
        return new Date(new Date(date).setHours(date.getHours() + hours));
    }

    const planduecheck = (info) => {
        const d1 = new Date();
        // Loop through currentSubscriptionArray
        if (info.currentSubscriptionArray.legnth != 0) {

            // && info.currentSubscription.dueDate !==null
            var Profits = 0
            info.currentSubscriptionArray.map((data) => {
                console.log(info.email);
                if (d1.getTime() >= data.dueDate) {
                    const newBal =
                        parseInt(info.balance) +
                        parseInt(data.dueAmount);

                    let FilteredArray = info.currentSubscriptionArray.filter((a) => { if (a.dueDate != data.dueDate) { return a } });
                    console.log(FilteredArray)
                    updateUserBalanceandSub(newBal, data.dueAmount, FilteredArray);
                    console.log(data.dueDate);
                } else if (d1.getTime() < data.dueDate) {
                    var today = new Date();
                    var total = data.dueDate - data.dateSubscribed;
                    var progress = today - data.dateSubscribed;

                    console.log(Math.round(progress / total * 100) + "%");
                    // data.dueAmount-data.amount
                    const currentprofit = (((progress / total * 100) * (data.dueAmount - data.amount)) / 100) + Profits
                    console.log(currentprofit)
                    Profits = currentprofit

                    console.log(data.dueDate - d1.getTime());
                    const planprogress =
                        ((data.dueDate - d1.getTime()) /
                            data.dueDate) *
                        100;
                    console.log(planprogress);
                    let date = new Date(data.dueDate);
                    console.log(date.toString());
                }
            })

            console.log(Profits)
            // intrestprogress(Profits)
            setprofits(Profits)
        } else {
            console.log(info.email);
        }

        // const d1 = new Date();
        // if (info.currentSubscription != null) {
        //     // && info.currentSubscription.dueDate !==null
        //     if (d1.getTime() >= info.currentSubscription.dueDate) {
        //         const newBal =
        //             parseInt(info.balance) +
        //             parseInt(info.currentSubscription.dueAmount);
        //         updateUserBalanceandSub(newBal, info.currentSubscription.dueAmount);
        //         console.log(info.currentSubscription.dueDate);
        //     } else if (d1.getTime() < info.currentSubscription.dueDate) {
        //         var today = new Date();
        //         var total = info.currentSubscription.dueDate - info.currentSubscription.dateSubscribed;
        //         var progress = today - info.currentSubscription.dateSubscribed;

        //         console.log(Math.round(progress / total * 100) + "%");
        //         const currentprofit = ((progress / total * 100) * info.currentSubscription.dueAmount) / 100
        //         console.log(currentprofit)
        //         intrestprogress(currentprofit)
        //         setprofits(currentprofit)
        //         console.log(info.currentSubscription.dueDate - d1.getTime());
        //         const planprogress =
        //             ((info.currentSubscription.dueDate - d1.getTime()) /
        //                 info.currentSubscription.dueDate) *
        //             100;
        //         console.log(planprogress);
        //         let date = new Date(info.currentSubscription.dueDate);
        //         console.log(date.toString());
        //     }
        // } else {
        //     console.log(info.email);
        // }
    };

    const updateUserBalanceandSub = (bal, addedbal, newarray) => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        const balanceToUse = parseFloat(userdetails.balance) + parseFloat(bal)
        washingtonRef
            .update({
                balance: bal,
                currentSubscription: null,
                currentSubscriptionArray: newarray,
                totalearnings: 0,
            })
            .then(function () {
                console.log("balance successfully updated!");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata(userids);
    };


    // const intrestprogress = (bal) => {
    //     var userid = f.auth().currentUser;
    //     var userids = userid.uid;
    //     var washingtonRef = db.collection("users").doc(userids);
    //     const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
    //     washingtonRef
    //         .update({
    //             totalearning: 0,
    //         })
    //         .then(function () {
    //             console.log("Document successfully updated!");
    //         })
    //         .catch(function (error) {
    //             console.error("Error updating document: ", error);
    //         });
    // };

    return (
        <div>
            {/* Required meta tags */}
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* Bootstrap CSS */}
            <link rel="stylesheet" href="/font-awesome/css/all.css" />
            <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
            <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
            <link rel="stylesheet" href="/css/sidebar.css" />
            <title>oceanic-invest</title>
            <div id="wrapper">
                <div className="overlay" />
                {/* <Nav /> */}
                <nav className="navbar navbar-inverse fixed-top" id="sidebar-wrapper" role="navigation">
                    <ul className="nav sidebar-nav">
                        <div className="sidebar-header">
                            <div className="sidebar-brand">
                                <a href="https://oceanic-invest.com/auth/home">
                                    <img src="/images/logo.svg" height={90} width={200} className="img-fluid" alt="" />
                                </a>
                            </div>
                        </div>
                        <li>
                            <a id="home" href="/">Home</a>
                        </li>

                        <li>
                            <a id="dashboard"  style={{ color: 'white', important: 'true' }}>Profit:       ${Math.round(parseFloat(profits) + parseFloat(userdetails.totalearnings))} </a>
                        </li>
                        <li>
                            <a id="dashboard" href="/dashboard">Dashboard</a>
                        </li>
                        <li>
                            <a id="profile" href="/settings">Profile</a>
                        </li>
                        <li>
                            <a id="deposit" href="/deposit">Deposit</a>
                        </li>
                        <li className="nav-item dropdown" id="withdraw">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Withdraw
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a className="dropdown-item" href="/withdrawaleth">
                                        <i className="fa-brands fa-ethereum" />
                                        Ethereum Withdraw
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/withdrawal">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-currency-bitcoin" viewBox="0 0 16 16">
                                            <path d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25L5.5 13zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28V4.487zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927V8.539z" />
                                        </svg>
                                        Bitcoin Withdraw
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/withdrawalusdt">
                                        <img src="/images/usdt.png" className="img-fluid" alt="" /> USDT Withdraw
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a id="transaction" href="/transactions">Transaction</a>
                        </li>
                        <li>
                            <a id="support" href="/support">
                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                </svg>
                                Support</a>
                        </li>

                        <li>
                            <a onClick={logout} href="/settings">Logout</a>
                        </li>

                    </ul></nav>
                <div id="page-content-wrapper bg-white">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                    <div className="d-flex justify-content-between">
                        <div className>
                        </div>
                        <div className=" me-2 mt-3">
                            <p>Translate</p>
                            <div id="google_translate_element" />
                        </div>
                    </div>
                    <div className="container mb-3">
                        <div className="row">
                            <div className="col-lg-12 col-lg-offset-2">
                                <div className="row w-100">
                                    <div className="w-100 d-flex mt-3">
                                        <i className="fa fa-dashboard" style={{ fontSize: '40px' }} />
                                        <h3>Dashboard</h3>
                                    </div>
                                    <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mb-2">
                                        <div className="card l-bg-orange-dark bgcolor">
                                            <div className="card-statistic-3 p-4">
                                                <div className="card-icon card-icon-large" />
                                                <div className="mb-3">
                                                    <h5 className="card-title mb-0 text-white">Investment Package</h5>
                                                </div>
                                                <div className="row align-items-center d-flex">
                                                    <div className="col-7">
                                                        <div className="d-flex align-items-center mb-0 text-white">
                                                            <p>
                                                                <a href="/deposit" className=" btn bg-white text-gray text-capitalize border-box">invest now</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        <div className>
                                                            <span className="text-white">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        dataready && (
                                            userdetails.currentSubscriptionArray.map((obj, i) => (
                                                <>
                                                    <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mb-2">
                                                        <div className="card l-bg-orange-dark bgcolor">
                                                            <div className="card-statistic-3 p-4">
                                                                <div className="card-icon card-icon-large" />
                                                                <div className="mb-3">
                                                                    <h5 className="card-title mb-0 text-white">{obj.currentPlan}  is running</h5>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <h5 className="card-title mb-0 text-white">it will expire on {new Date(obj.dueDate).toDateString()}</h5>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>


                                            ))

                                        )
                                    }

                                    <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mb-2">
                                        <div className="card l-bg-orange-dark bgcolor w-100">
                                            <div className="card-statistic-3 p-4">
                                                <div className="card-icon card-icon-large" />
                                                <div className="mb-4">
                                                    <h5 className="card-title mb-0 text-white">Capital</h5>
                                                </div>
                                                <div className="row align-items-center mb-2 d-flex">
                                                    <div className="col-7">
                                                        <h2 className="d-flex align-items-center mb-0 text-white">
                                                            ${userdetails.Totaldeposit}
                                                        </h2>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        <a href="/deposit" className=" border-box btn bg-white text-gray text-capitalize">invest now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mb-2">
                                        <div className="card l-bg-orange-dark bgcolor w-100">
                                            <div className="card-statistic-3 p-4">
                                                <div className="card-icon card-icon-large" />
                                                <div className="mb-4">
                                                    <h5 className="card-title mb-0 text-white">Profit</h5>
                                                </div>
                                                <div className="row align-items-center mb-2 d-flex">
                                                    <div className="col-7">
                                                        <h2 className="d-flex align-items-center mb-0 text-white">
                                                          
                                                            ${Math.round(parseFloat(profits) + parseFloat(userdetails.totalearnings))}
                                        
                                                        </h2>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                        {/* ${Math.round(parseFloat(profits))}  */}
                                                          {/* ${profits + parseFloat(userdetails.totalearnings)} */}

                                    <div className="col-sm-12 col-lg-4 col-md-4 mt-3 mb-2">
                                        <div className="card l-bg-orange-dark bgcolor w-100">
                                            <div className="card-statistic-3 p-4">
                                                <div className="card-icon card-icon-large" />
                                                <div className="mb-4">
                                                    <h5 className="card-title mb-0 text-white">Balance</h5>
                                                </div>
                                                <div className="row align-items-center mb-2 d-flex">
                                                    <div className="col-7">
                                                        <h2 className="d-flex align-items-center mb-0 text-white">
                                                            ${userdetails.balance}
                                                        </h2>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        <a href="/deposit" className=" btn border-box bg-white text-gray text-capitalize">invest now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 position-relative start-0 top-0">
                        <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: '46px', width: '100%' }} src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22LSIN%3ATCS%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ANFLX%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ATSLA%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AAAPL%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ADOGEUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AADAUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AMSFT%22%7D%5D%2C%22showSymbolLogo%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A78%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D" frameBorder={0} />
                    </div>
                    <div className="bg-white">
                        <div className="container mt-3 mb-3">
                            <div className="row">
                                <div className="col-md-3 mt-2 mb-2">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: '100%', width: '100%' }} src="https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22TVC%3AGOLD%22%2C%22width%22%3A350%2C%22height%22%3A220%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22rgba(255%2C%20152%2C%200%2C%201)%22%2C%22underLineColor%22%3A%22rgba(255%2C%20255%2C%200%2C%200.3)%22%2C%22underLineBottomColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200)%22%2C%22isTransparent%22%3Afalse%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fmini-chart%2F%22%7D" frameBorder={0} />
                                </div>
                                <div className="col-md-3 mt-2 mb-2">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: '100%', width: '100%' }} src="https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22TVC%3ASILVER%22%2C%22width%22%3A350%2C%22height%22%3A220%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22rgba(255%2C%20152%2C%200%2C%201)%22%2C%22underLineColor%22%3A%22rgba(255%2C%20255%2C%200%2C%200.3)%22%2C%22underLineBottomColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200)%22%2C%22isTransparent%22%3Afalse%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fmini-chart%2F%22%7D" frameBorder={0} />
                                </div>
                                <div className="col-md-3 mt-2 mb-2">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: '100%', width: '100%' }} src="https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22NASDAQ%3AAAPL%22%2C%22width%22%3A350%2C%22height%22%3A220%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%201)%22%2C%22underLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200.3)%22%2C%22underLineBottomColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200)%22%2C%22isTransparent%22%3Afalse%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fmini-chart%2F%22%7D" frameBorder={0} />
                                </div>
                                <div className="col-md-3 mt-2 mb-2">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: '100%', width: '100%' }} src="https://s.tradingview.com/embed-widget/mini-symbol-overview/?locale=en#%7B%22symbol%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22width%22%3A350%2C%22height%22%3A220%2C%22dateRange%22%3A%2212M%22%2C%22colorTheme%22%3A%22light%22%2C%22trendLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%201)%22%2C%22underLineColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200.3)%22%2C%22underLineBottomColor%22%3A%22rgba(41%2C%2098%2C%20255%2C%200)%22%2C%22isTransparent%22%3Afalse%2C%22autosize%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22mini-symbol-overview%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fmini-chart%2F%22%7D" frameBorder={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white mt-3 mb-3">
                        <div className="container">
                            <div className="row">
                                <h3 className="text-center text-capitalize mt-3 mb-3">Stocks</h3>
                                <div className="col-md-8">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: 'calc(468px)', width: '100%' }} src="https://s.tradingview.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22Apple%22%2C%22AAPL%7C1D%22%5D%2C%5B%22Google%22%2C%22GOOGL%7C1D%22%5D%2C%5B%22Microsoft%22%2C%22MSFT%7C1D%22%5D%2C%5B%22LSIN%3ATCS%7C1D%22%5D%2C%5B%22NASDAQ%3ATSLA%7C1D%22%5D%2C%5B%22TVC%3AGOLD%7C12M%22%5D%2C%5B%22TVC%3ASILVER%7C1D%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A1000%2C%22height%22%3A500%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Afalse%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Afalse%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22price-and-percent%22%2C%22chartType%22%3A%22line%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fsymbol-overview%2F%22%7D" frameBorder={0} />
                                </div>
                                <div className="col-md-4">
                                    <iframe scrolling="no" allowTransparency="true" style={{ boxSizing: 'border-box', height: 'calc(418px)', width: '100%' }} src="https://s.tradingview.com/embed-widget/technical-analysis/?locale=en#%7B%22interval%22%3A%221m%22%2C%22width%22%3A425%2C%22isTransparent%22%3Afalse%2C%22height%22%3A450%2C%22symbol%22%3A%22NASDAQ%3ATSLA%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Ftechnical-analysis%2F%22%7D" frameBorder={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white mb-3">
                        <div className="container">
                            <div className="row">
                                <h3 className="text-center text-capitalize mt-3 mb-3">Cryptocurrencies</h3>
                                <iframe allowTransparency="true" style={{ boxSizing: 'border-box', height: 'calc(458px)', width: '100%' }} src="https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22width%22%3A1000%2C%22height%22%3A490%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D" frameBorder={0} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Optional JavaScript; choose one of the two! */}
            {/* Option 1: Bootstrap Bundle with Popper */}
            {/* Option 2: Separate Popper and Bootstrap JS */}
            {/*


*/}
        </div>
    )
}

export default Dashboard