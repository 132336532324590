import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';

import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function Seettings() {

  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)

  const [password, setpassword] = useState("")
  const [password2, setpassword2] = useState("")
  const [fullname, setfullname] = useState("")
  const [address, setaddress] = useState("")
  const [phonenumber, setphonenumber] = useState("")
  const [dateOfBirth, setdateOfBirth] = useState("")
  const [country, setcountry] = useState("")


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setfullname(doc.data().fullname)
          setaddress(doc.data().Address)
          setphonenumber(doc.data().Phoneno)
          setdateOfBirth(doc.data().DateOfBirth)
          // Phoneno:phonenumber,
          // Address:address,
          // fullname:fullname,
          // DateOfBirth:dateOfBirth,
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };



  const updateUserProfile = async () => {

    let userid = f.auth().currentUser;
    let userids = userid.uid;
    var docRef = db.collection("users").doc(userids);
    console.log(phonenumber, address, fullname, dateOfBirth)
    console.log(fullname)
    const update = await docRef
      .update({
        Phoneno: phonenumber,
        // Address: address,
        fullname: fullname,
        // DateOfBirth: dateOfBirth,
        // address: address
      })
      .then(function () {
        console.log("Document successfully updated!");
        Swal.fire(
          'Update Complete!',
          'Your account has been updataed.',
          'success'
        )
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };


  const changepassword = () => {
   console.log(password)
    if (password == "") {
      Swal.fire(
        'No Selected Passowrd?',
        'Please Select A Valid Password',
      )
      return

    }

    if (password != password2) {
      Swal.fire(
        'Password does not match?',
        'Password must match',
      )
      return
    }
    setloading(true)
    var user = f.auth().currentUser;
    user
      .updatePassword(password)
      .then(function () {
        setloading(false)
        Swal.fire({
          icon: 'success',
          title: 'password changed',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        console.log(error)
        setloading(false)
        Swal.fire(
          'Something went wrong',
          `${error.message}`,
        )

      });
  };

  const save = () => {
    Swal.fire({
      icon: 'success',
      title: 'Account has been updated successfully',
      showConfirmButton: false,
      timer: 1500
    })
  }
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="/font-awesome/css/all.css" />
      <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
      <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
      <link rel="stylesheet" href="/css/sidebar.css" />
      <link rel="stylesheet" href="/css/profile.css" />
      <title>oceanic-invest</title>
      <div id="wrapper">
        <div className="overlay" />
        {/* Sidebar */}
        <Nav />
        <div id="page-content-wrapper bg-white">
          <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
            <span className="hamb-top" />
            <span className="hamb-middle" />
            <span className="hamb-bottom" />
          </button>
          <div className="d-flex justify-content-between">
            <div className>
            </div>
            <div className=" me-2 mt-3">
              <p>Translate</p>
              <div id="google_translate_element" />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Page title */}
                <div className="my-5">
                  <h3>My Profile</h3>
                  <hr />
                </div>
                {/* Form START */}

                <div class=" col-xxl-8 mb-5 mb-xxl-0">
                  <div className="bg-secondary-soft px-4 py-5 rounded">
                    <div className="row g-3">
                      <h4 className="mb-4 mt-0">Contact detail</h4>
                      {/* First Name */}
                      <div className="col-md-6">
                        <label className="form-label">First Name *</label>
                        <input type="text" className="form-control" placeholder aria-label="First name" defaultValue={fullname} onChange={(e) => { setfullname(e.target.value) }} />
                      </div>
                      {/* Last name */}
                      {/* <div className="col-md-6">
                        <label className="form-label">Last Name *</label>
                        <input type="text" className="form-control" placeholder aria-label="Last name" defaultValue="laswma" />
                      </div> */}
                      {/* Phone number */}
                      <div className="col-md-6">
                        <label className="form-label">Phone number *</label>
                        <input type="text" className="form-control" placeholder aria-label="Phone number" defaultValue={phonenumber} onChange={(e) => { setphonenumber(e.target.value) }} />
                      </div>
                      {/* Email */}
                      <div className="col-md-6">
                        <label htmlFor="inputEmail4" className="form-label">Email *</label>
                        <input type="email" className="form-control" id="inputEmail4" defaultValue={userdetails.email} name="email" readOnly />
                      </div>
                      {/* Skype */}
                      <div className="col-md-6">
                        <label className="form-label">Country</label>
                        <input type="text" className="form-control" placeholder aria-label="Phone number" defaultValue="Austria" />
                      </div>
                      {/* <div className="col-md-6">
                      <label className="form-label">Account Balance</label>
                      <input type="text" className="form-control bg-white" disabled aria-label="Phone number"  defaultValue={phonenumber} onChange={(e)=>{setphonenumber(e.target.value)}} />
                    </div> */}
                      {/* <div className="col-md-6">
                      <label className="form-label">Referral Code</label>
                      <input type="text" className="form-control bg-white" disabled aria-label="Phone number" defaultValue="b88pRA0" />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Referral Link</label>
                      <input type="text" className="form-control bg-white" disabled aria-label="Phone number" defaultValue="oceanic-invest.com/b88pRA0" />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Investment</label>
                      <input type="text" className="form-control bg-white" disabled aria-label="Phone number" defaultValue="$0" />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Investment Package</label>
                      <input type="text" className="form-control bg-white" disabled aria-label="Phone number" defaultValue />
                    </div> */}
                    </div> {/* Row END */}
                  </div>
                </div>
              </div> {/* Row END */}
              {/* Social media detail */}
              <div className="row mb-4">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className>
                    <div className="row">
                      <h4 className="my-4 mt-0">Wallet Addresses</h4>
                      {/* Facebook */}
                      <form action="https://oceanic-invest.com/auth/update/wallet/address" method="POST">
                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                                                    <div className="col-md-6 mt-3 mb-3 w-100">
                          <label className="form-label">
                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-currency-bitcoin" viewBox="0 0 16 16">
                              <path d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25L5.5 13zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28V4.487zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927V8.539z" />
                            </svg>
                            Bitcoin *</label>
                          <input type="text" className="form-control" required name="bitcoin_wallet_address" placeholder="Bitcoin Wallet Address" aria-label="Facebook" defaultValue />
                        </div>
                        {/* Twitter */}
                        <div className="col-md-6 mt-3 mb-3 w-100">
                          <label className="form-label"><i className="fa-brands fa-ethereum" />Ethereum *</label>
                          <input type="text" className="form-control" required name="ethereum_wallet_address" placeholder="Ethereum Wallet Address" aria-label="Twitter" defaultValue />
                        </div>
                        {/* Linkedin */}
                        <div className="col-md-6 mt-3 mb-3 w-100">
                          <label className="form-label"><img src="/images/usdt.png" className="img-fluid" alt="" />USDT (Tether) *</label>
                          <input type="text" className="form-control" required name="tether_wallet_address" placeholder="USDT (Tether) Wallet Address" aria-label="Linkedin" defaultValue />
                        </div>
                        <div className="col-md-6 mt-3 mb-3">
                          <div className="text-center">
                            <button type="Button" className="btn btn-primary btn-lg" onClick={updateUserProfile}>Submit</button>
                          </div>
                        </div>
                      </form>
                    </div> {/* Row END */}
                  </div>
                </div>
                {/* change password */}
                <div className="ccol-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="bg-secondary-soft px-4 py-5 rounded">
                    <h4 className="my-4">Change Password</h4>
                    {/* Old password */}
                    <form action="https://oceanic-invest.com/auth/update/password" method="POST">
                      <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                                                    <div className="row g-3">
                        <div className="col-md-6">
                          <label className="form-label">Old password *</label>
                          <input type="password" name="old_password" className="form-control" id="exampleInputPassword1" />
                        </div>
                        {/* New password */}
                        <div className="col-md-6">
                          <label  className="form-label">New password *</label>
                          <input type="password" name="new_password" onChange={(e) => { setpassword(e.target.value) }} className="form-control" id="exampleInputPassword2" />
                        </div>
                        {/* Confirm password */}
                        <div className="col-md-12">
                          <label  className="form-label">Confirm Password *</label>
                          <input type="password" name="confirm_password" className="form-control" onChange={(e) => { setpassword2(e.target.value) }} id="exampleInputPassword3" />
                        </div>
                        <div className="text-center">
                          <button type="Button" className="btn btn-primary btn-lg" onClick={changepassword}>Change Password</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> {/* Row END */}
              {/* button */}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Seettings