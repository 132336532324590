import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function TransactionHistory() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [dataready, setdataready] = useState(false)


    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                    setdataready(true)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    return (
        <div>
            {/* Required meta tags */}
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* Bootstrap CSS */}
            <link rel="stylesheet" href="/font-awesome/css/all.css" />
            <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
            <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
            <link rel="stylesheet" href="/responsive-table-bootstrap/Responsive-Table.css" />
            <link href="https://cdn.jsdelivr.net/gh/bbbootstrap/libraries@main/datedropper.css" rel="stylesheet" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
            <link rel="stylesheet" href="/css/sidebar.css" />
            <link rel="stylesheet" href="/css/deposit.css" />
            <link rel="stylesheet" href="/preloader/preloader.css" />
            <title>oceanic-invest</title>
            {/* <div className="preloader position-fixed start-0 top-0 bottom-0 end-0 min-vh-100">
                <div className="loader">
                    <svg viewBox="0 0 120 120" className="loaderSvg" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle className="load one" cx={60} cy={60} r={40} />
                        <circle className="load two" cx={60} cy={60} r={40} />
                        <circle className="load three" cx={60} cy={60} r={40} />
                        <g>
                            <circle className="point one" cx={45} cy={70} r={5} />
                            <circle className="point two" cx={60} cy={70} r={5} />
                            <circle className="point three" cx={75} cy={70} r={5} />
                        </g>
                    </svg>
                </div>
            </div> */}
            <div id="wrapper">
                <div className="overlay" />
                {/* Sidebar */}
                <Nav />
                <div id="page-content-wrapper bg-white">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                    <div className="d-flex justify-content-between">
                        <div className>
                        </div>
                        <div className=" me-2 mt-3">
                            <p>Translate</p>
                            <div id="google_translate_element" />
                        </div>
                    </div>
                    <div className="container mt-5 bg-white">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-white p-4">
                                    <div className="border w-100 p-3">
                                        <h4>History</h4>
                                        <div className>
                                            <div className>
                                                <form action="https://oceanic-invest.com/auth/transactions" method="POST">
                                                    <div className="row p-3">
                                                        <div className="col-md-3">
                                                            <label>Transactions</label>
                                                            <select className="form-select" name="category" aria-label="Default select example">
                                                                <option selected disabled value>Choose Transaction</option>
                                                                <option value="all">All Transaction</option>
                                                                <option value="deposit">Deposits</option>
                                                                <option value="withdrawal">Withdrawals</option>
                                                                <option value="bitcoin">Bitcoin Transactions Only</option>
                                                                <option value="ethereum">Ethereum Transactions Only</option>
                                                                <option value="tether">USDT (Tether) Transactions Only</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>From</label>
                                                            <input type="text" name="from" className="form-control" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>To</label>
                                                            <input type="text" name="to" className="form-control" />
                                                        </div>
                                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                                                <div className="col-md-3">
                                                            <label />
                                                            <button className="btn bgcolor text-white w-100">Search <i className="fas fa-search" /></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="text-center text-capitalize">All Transactions</p>
                            <div className="table-responsive">
                                <div className="d-flex justify-content-end">
                                </div><table className="table table-striped  table-mobile-responsive table-mobile-sided table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-dark">#</th>
                                            <th scope="col" className="text-dark">Amount</th>
                                            <th scope="col" className="text-dark">Cryptocurrency</th>
                                            <th scope="col" className="text-dark">Type</th>
                                            <th scope="col" className="text-dark">Investment Package</th>
                                            <th scope="col" className="text-dark">Date</th>
                                            <th scope="col" className="text-dark">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            dataready && (
                                                userdetails.withdrawalhistory.map((obj, i) => (
                                                    <tr>
                                                        <td data-content="#">{i}</td>
                                                        <td>{obj.mode}</td>
                                                        <td>${obj.amt}
                                                        </td>
                                                        <td>{obj.coin}</td>
                                                        <td>{new Date(obj.date).toLocaleString()}
                                                        </td>
                                                    </tr>

                                                ))

                                            )
                                        }
                                        {
                                            dataready && (
                                                userdetails.Deposithistory.map((obj, i) => (
                                                    <tr>
                                                        <td >{i}</td>
                                                        <td>{obj.mode}</td>
                                                        <td>${obj.amt}
                                                        </td>
                                                        <td>{obj.coin}</td>
                                                        <td>{new Date(obj.date).toLocaleString()}
                                                        </td>
                                                    </tr>

                                                ))

                                            )
                                        }
                                        {/* <tr className>
                                            <td data-content="#">1</td>
                                            <td data-content="Amount">3000 </td>
                                            <td data-content="Cryptocurrency">bitcoin</td>
                                            <td data-content="Type">deposit</td>
                                            <td data-content="Investment Package">gold</td>
                                            <td data-content="Date">
                                                2023-05-27
                                            </td>
                                            <td data-content="Status">
                                                <p className="p-3 alert-primary text-dark">Pending</p>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Optional JavaScript; choose one of the two! */}
                {/* Option 1: Bootstrap Bundle with Popper */}
                {/* Option 2: Separate Popper and Bootstrap JS */}
                {/*
        
        
        */}
            </div></div>
    )
}

export default TransactionHistory