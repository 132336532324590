import React from 'react'
import Nav from '../Components/Nav'

function Deposit() {
  return (
    <div>
    {/* Required meta tags */}
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    {/* Bootstrap CSS */}
    <link rel="stylesheet" href="/font-awesome/css/all.css" />
    <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
    <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
    <link rel="stylesheet" href="/css/sidebar.css" />
    <link rel="stylesheet" href="/css/deposit.css" />
    <title>oceanic-invest</title>
    <div id="wrapper">
      <div className="overlay" />
      {/* Sidebar */}
     <Nav/>
      <div id="page-content-wrapper bg-white">
        <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
          <span className="hamb-top" />
          <span className="hamb-middle" />
          <span className="hamb-bottom" />
        </button>
        <div className="d-flex justify-content-between">
          <div className>
          </div>
          <div className=" me-2 mt-3">
            <p>Translate</p>
            <div id="google_translate_element" />
          </div>
        </div>
        <div className="container mb-3 mt-3 bg-white">
          <div className="row">
            <div className="w-100 d-flex mt-3 mb-3">
              <i className="fa fa-download" style={{fontSize: '40px'}} />
              <h3 className="ms-2">Make Deposit</h3>
            </div>
            <div className="w-100 position-relative start-0 top-0">
              <iframe scrolling="no" allowTransparency="true" style={{boxSizing: 'border-box', height: '46px', width: '100%'}} src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22LSIN%3ATCS%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ANFLX%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3ATSLA%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AAAPL%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ADOGEUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AADAUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22NASDAQ%3AMSFT%22%7D%5D%2C%22showSymbolLogo%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A78%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D" frameBorder={0} />
            </div>
            <h3 className="text-center text-capitalize">Choose your investment</h3>

            <div className="col-lg-4 col-md-4 col-sm-12 ps-3 pe-3">
              <div className="pricing-box bg-white mt-4">
                <h3 className="text-center">Silver</h3>
                <img className="mx-auto d-block rounded" src="/images/coin.png" />
                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Investment:</b>
                    $100 - $999</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Daily Profit:</b>
                    4%</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Duration:</b>
                    7 days</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Referral Bonus:</b>
                    5%
                  </p>
                </div>
                <div className="pricing-plan mt-4 pt-2">
                  <h4 className="text-muted">$100 - $999</h4>
                </div>
                <div className="mt-4 pt-3 text-center">
                  <a href="/confirmdeposit?plan=1" className="btn bgcolor text-white btn-rounded">Invest Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ps-3 pe-3">
              <div className="pricing-box bg-white mt-4">
                <h4 className="text-center text-dark">Gold</h4>
                <img className="mx-auto d-block rounded" src="/images/gold.png" />
                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Investment:</b>
                    $1,000 - $9,999</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Daily Profit:</b>
                    6%</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Duration:</b>
                    7 days</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Referral Bonus:</b>
                    5%
                  </p>
                </div>
                <div className="pricing-plan mt-4 pt-2">
                  <h4 className="text-muted">$1,000 - $9,999</h4>
                </div>
                <div className="mt-4 pt-3 text-center">
                   <a href="/confirmdeposit?plan=2" className="btn bgcolor text-white btn-rounded">Invest Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ps-3 pe-3">
              <div className="pricing-box bg-white mt-4">
                <i className="mdi mdi-account-multiple-plus h1" />
                <h4 className="text-center">Diamond</h4>
                <img className="mx-auto d-block rounded" src="/images/diamond.png" />
                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Investment:</b>
                    $10,000 - $29,999</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Daily Profit:</b>
                    8%</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Duration:</b>
                    14 days</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Referral Bonus:</b>
                    5%
                  </p>
                </div>
                <div className="pricing-plan mt-4 pt-2">
                  <h4 className="text-muted">$10,000 - $29,999 </h4>
                </div>
                <div className="mt-4 pt-3 text-center">
                <a href="/confirmdeposit?plan=3" className="btn bgcolor text-white btn-rounded">Invest Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ps-3 pe-3">
              <div className="pricing-box bg-white mt-4 mb-3">
                <i className="mdi mdi-account-multiple-plus h1" />
                <h4 className="text-center">Platinum</h4>
                <img className="mx-auto d-block rounded" src="/images/platinum.png" />
                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Investment:</b>
                    $30,000 - $49,999</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Daily Profit:</b>
                    10%</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Duration:</b>
                    30 days</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Referral Bonus:</b>
                    5%
                  </p>
                </div>
                <div className="pricing-plan mt-4 pt-2">
                  <h4 className="text-muted">$30,000 - $49,999</h4>
                </div>
                <div className="mt-4 pt-3 text-center">
                <a href="/confirmdeposit?plan=4" className="btn bgcolor text-white btn-rounded">Invest Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ps-3 pe-3">
              <div className="pricing-box bg-white mt-4 mb-3">
                <i className="mdi mdi-account-multiple-plus h1" />
                <h4 className="text-center">Zenith</h4>
                <img className="mx-auto d-block rounded" src="/images/zenith.png" />
                <div className="mt-4 pt-2">
                  <p className="mb-2 f-18">Features</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Investment:</b>
                    $50,000 - max</p>
                  <p className="mb-2"><i className="mdi mdi-checkbox-marked-circle text-success f-18 mr-2" /><b>Daily Profit:</b>
                    15%</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Duration:</b>
                    31 days</p>
                  <p className="mb-2"><i className="mdi mdi-close-circle text-danger f-18 mr-2" /><b>Referral Bonus:</b>
                    5%
                  </p>
                </div>
                <div className="pricing-plan mt-4 pt-2">
                  <h4 className="text-muted">$50,000 - max</h4>
                </div>
                <div className="mt-4 pt-3 text-center">
                <a href="/confirmdeposit?plan=5" className="btn bgcolor text-white btn-rounded">Invest Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Optional JavaScript; choose one of the two! */}
      {/* Option 1: Bootstrap Bundle with Popper */}
      {/* Option 2: Separate Popper and Bootstrap JS */}
      {/*


*/}
    </div></div>
  )
}

export default Deposit