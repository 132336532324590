import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function WithdrwalUsdt() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1)
    const [coin, setcoin] = useState("USDT TRC0")
    const [wallet, setwallet] = useState("")
    const [Amount, setAmount] = useState("")


    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const SetcoinTouse = (no) => {

        if (no == 2) {
            setcoin("Tether")
        }
        if (no == 6) {
            setcoin("Ethereum")
        }
        if (no == 7) {
            setcoin("Bitcoin")
        }
        setstep(2)
    }
    const confirmWitdrawal = async () => {

        if (Amount != "") {
            console.log(Amount)
            console.log(userdetails.balance)
            if (parseFloat(userdetails.balance) < parseFloat(Amount)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "balance not enough",
                    footer: '<a href="">Why do I have this issue?</a>'
                })
            } else {
                await updatewithdrawalhistory()
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "no Amount selected",
                footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    }


    const updatewithdrawalhistory = async () => {

        if (coin == "" || Amount == "" || wallet == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Select wallet, Coin , and Amount to procecced withdrawal",
                footer: '<a href="">Why do I have this issue?</a>'
            })
            return 0
        }
        // console.log(cointype)
        setloading(true)
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            Totalwithdrawal: increment,
            Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",

            }),
        });
        await updateUserBalance()
        await CreatWithdrawalObj()
    };

    const updateUserBalance = async (bal) => {
        const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = await db.collection("users").doc(userids);
        washingtonRef
            .update({
                balance: newEarings,
            })
            .then(function () {
                console.log("Document successfully up2dated!");
                // alert("withdrawal was successful we will get back to you");
                Swal.fire({
                    icon: 'success',
                    title: 'withdrawal was successful we will get back to you!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata();
    };
    const CreatWithdrawalObj = async () => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        let uobj = {
            fullname: userdetails.fullname,
            email: userdetails.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userid: userids,
            info: {
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",
            }
        };
        const userdetail1 = await db
            .collection("withdrawals")
            .add(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };

    return (
        <div>
            {/* Required meta tags */}
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* Bootstrap CSS */}
            <link rel="stylesheet" href="/font-awesome/css/all.css" />
            <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
            <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
            <link rel="stylesheet" href="/css/sidebar.css" />
            <link rel="stylesheet" href="/css/withdraw.css" />
            <title>oceanic-invest</title>
            <div id="wrapper">
                <div className="overlay" />
                {/* Sidebar */}
                <Nav />
                <div id="page-content-wrapper bg-white">
                    <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
                        <span className="hamb-top" />
                        <span className="hamb-middle" />
                        <span className="hamb-bottom" />
                    </button>
                    <div className="d-flex justify-content-between">
                        <div className>
                        </div>
                        <div className=" me-2 mt-3">
                            <p>Translate</p>
                            <div id="google_translate_element" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="main">
                                <div className="left">
                                    <div className="text text-capitalize text-center">USDT TRC20</div>
                                    <img className="img-fluid d-block mx-auto" src="https://img.icons8.com/?size=1x&id=dCeLaRxYok4m&format=png" alt="" />
                                </div>
                                <div className="right">
                                    <div className="title text-capitalize">Withdrawal</div>
                                    <h4>
                                        Balance:      ${userdetails.balance}
                                    </h4>
                                    <form >
                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <label htmlFor className="mt-3">Amount</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input name="amount" type="text" onChange={(e)=>{setAmount(e.target.value)}} required className="form-control" aria-label="Amount (to the nearest dollar)" />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        <label htmlFor className="text-capitalize">bitcoin withdrawal address</label>
                                        <input className="address" onChange={(e)=>{setwallet(e.target.value)}} required placeholder="Enter Wallet Address" name="address" defaultValue type="text" />
                                        <input type="hidden" name="cryptocurrency" defaultValue="bitcoin" hidden />
                                        <input className="button" type="button" defaultValue="button" onClick={confirmWitdrawal} />
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="main">
                                <div className="left">
                                    <div className="text text-capitalize text-center">USDT</div>
                                    <img className="img-fluid d-block mx-auto" src="/images/bitcoin.png" alt="" />
                                </div>
                                <div className="right">
                                    <div className="title text-capitalize">Withdrawal</div>
                                    <h4>
                                        Balance:$0
                                    </h4>
                                    <form >
                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <label htmlFor className="mt-3">Amount</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input name="amount" type="text" onChange={(e)=>{setAmount(e.target.value)}} required className="form-control" aria-label="Amount (to the nearest dollar)" />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        <label htmlFor className="text-capitalize">bitcoin withdrawal address</label>
                                        <input className="address" required placeholder="Enter Wallet Address" name="address" defaultValue type="text" />
                                        <input type="hidden" name="cryptocurrency" defaultValue="bitcoin" hidden />
                                        <input className="button" type="button" defaultValue="button" />
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="main">
                                <div className="left">
                                    <div className="text text-capitalize text-center">Bitcoin</div>
                                    <img className="img-fluid d-block mx-auto" src="https://img.icons8.com/?size=1x&id=ovHld7NfgG9g&format=gif" alt="" />
                                </div>
                                <div className="right">
                                    <div className="title text-capitalize">Withdrawal</div>
                                    <h4>
                                        Balance:$0
                                    </h4>
                                    <form >
                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <label htmlFor className="mt-3">Amount</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input name="amount" type="text" required onChange={(e)=>{setAmount(e.target.value)}} className="form-control" aria-label="Amount (to the nearest dollar)" />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        <label htmlFor className="text-capitalize">bitcoin withdrawal address</label>
                                        <input className="address" required placeholder="Enter Wallet Address" name="address" defaultValue type="text" />
                                        <input type="hidden" name="cryptocurrency" defaultValue="bitcoin" hidden />
                                        <input className="button" type="button" defaultValue="button" />
                                    </form>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="row">
                            <div className="main">
                                <div className="left">
                                    <div className="text text-capitalize text-center">Bitcoin</div>
                                    <img className="img-fluid d-block mx-auto" src="https://img.icons8.com/?size=1x&id=N6QeGIFVpoZ3&format=png" alt="" />
                                </div>
                                <div className="right">
                                    <div className="title text-capitalize">Withdrawal</div>
                                    <h4>
                                        Balance:$0
                                    </h4>
                                    <form >
                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <label htmlFor className="mt-3">Amount</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input name="amount" type="text" required className="form-control" aria-label="Amount (to the nearest dollar)" />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        <label htmlFor className="text-capitalize">bitcoin withdrawal address</label>
                                        <input className="address" required placeholder="Enter Wallet Address" name="address" defaultValue type="text" />
                                        <input type="hidden" name="cryptocurrency" defaultValue="bitcoin" hidden />
                                        <input className="button" type="button" defaultValue="button" />
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="main">
                                <div className="left">
                                    <div className="text text-capitalize text-center">Bitcoin</div>
                                    <img className="img-fluid d-block mx-auto" src="https://img.icons8.com/?size=1x&id=dCeLaRxYok4m&format=png" alt="" />
                                </div>
                                <div className="right">
                                    <div className="title text-capitalize">Withdrawal</div>
                                    <h4>
                                        Balance:$0
                                    </h4>
                                    <form >
                                        <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <label htmlFor className="mt-3">Amount</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input name="amount" type="text" required className="form-control" aria-label="Amount (to the nearest dollar)" />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        <label htmlFor className="text-capitalize">bitcoin withdrawal address</label>
                                        <input className="address" required placeholder="Enter Wallet Address" name="address" defaultValue type="text" />
                                        <input type="hidden" name="cryptocurrency" defaultValue="bitcoin" hidden />
                                        <input className="button" type="button" defaultValue="button" />
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* Optional JavaScript; choose one of the two! */}
                {/* Option 1: Bootstrap Bundle with Popper */}
                {/* Option 2: Separate Popper and Bootstrap JS */}
                {/*
        
        
        */}
            </div></div>
    )
}

export default WithdrwalUsdt