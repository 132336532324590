import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBROghvEYSzXrGpXvwIxb5WWhUhExAqU6U",
  authDomain: "oceanicinvest-308e8.firebaseapp.com",
  databaseURL: "https://oceanicinvest-308e8-default-rtdb.firebaseio.com",
  projectId: "oceanicinvest-308e8",
  storageBucket: "oceanicinvest-308e8.appspot.com",
  messagingSenderId: "95501910987",
  appId: "1:95501910987:web:2a89763a29a33bbb37ac4e"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()