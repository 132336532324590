import React from 'react'
import Nav from '../Components/Nav'

function Support() {
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="/font-awesome/css/all.css" />
      <link rel="stylesheet" href="/font-awesome/css/all.min.css" />
      <link rel="stylesheet" href="/font-awesome/css/fontawesome.min.css" />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
      <link rel="stylesheet" href="/css/sidebar.css" />
      <link rel="stylesheet" href="/css/support.css" />
      <title>oceanic-invest</title>
      <div id="wrapper">
        <div className="overlay" />
        {/* Sidebar */}
        <Nav />
        <div id="page-content-wrapper bg-white">
          <button type="button" className="hamburger animated fadeInLeft is-closed" data-toggle="offcanvas">
            <span className="hamb-top" />
            <span className="hamb-middle" />
            <span className="hamb-bottom" />
          </button>
          <div className="d-flex justify-content-between">
            <div className>
            </div>
            <div className=" me-2 mt-3">
              <p>Translate</p>
              <div id="google_translate_element" />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div>
                <div className="contact-form-wrapper d-flex justify-content-center">
                  <form className="contact-form">
                    <input type="hidden" name="_token" defaultValue="8wLGfkXGrQ6qNUYfR7MWftAFCeHYyO1yW5PEYiHq" />                            <h5 className="title">Contact us</h5>
                    <p className="description">Feel free to contact us if you need any assistance, any help or another question.
                    </p>
                    <div>
                      <input type="text" defaultValue="beckyham laswma" name="name" className="form-control rounded border-white mb-3 form-input" id="name" placeholder="Name" required />
                    </div>
                    <div>
                      <input type="email" defaultValue="goldsmega@gmail.com" name="email" className="form-control rounded border-white mb-3 form-input" placeholder="Email" required />
                    </div>
                    <div>
                      <textarea id="message" name="message" className="form-control rounded border-white mb-3 form-text-area" rows={5} cols={30} placeholder="Message" required defaultValue={""} />
                    </div>
                    <div className="submit-button-wrapper">
                      <input type="button" defaultValue="Send" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Optional JavaScript; choose one of the two! */}
        {/* Option 1: Bootstrap Bundle with Popper */}
        {/* Option 2: Separate Popper and Bootstrap JS */}
        {/*
    
    
    */}
      </div></div>
  )
}

export default Support